<template>
  <aw-cms-page-sidebar-wrapper :menu-items="menuItems" :selected-item="currentItem" @navigate="clickOnPetrolMenu">
    <template #content>
      <slot />
    </template>
  </aw-cms-page-sidebar-wrapper>
</template>

<script>
  import { useRoute } from 'nuxt/app';
  import { mapState } from 'pinia';
  import { useContentMenusStore } from '~~/common/stores/contentMenus.js';
  import { removeLocaleFromUrl } from '~~/common/utils/urlTransformation.js';
  import { useContentsStore } from '~~/common/stores/contents.js';
  import { useBreadcrumbStore } from '~~/common/stores/breadcrumb.js';

  import AwCmsPageSidebarWrapper from '~~/common/components/Page/Content/Wrapper/Sidebar/AwCmsPageSidebarWrapper.vue';

  export default {
    name: 'AwPetrolMenu',
    components: {
      AwCmsPageSidebarWrapper,
    },
    props: {
      petrolStation: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      ...mapState(useContentMenusStore, {
        petrolMenuItems (state) {
          return state.petrolMenu;
        },
      }),
      menuItems () {
        return [
          {
            id: 'aw.pages.petrol.menu.petrol_stations',
            name: this.$awt('aw.pages.petrol.menu.petrol_stations'),
            link: '/petrol',
          },
          ...this.petrolMenuItems.map((item, index) => ({
            id: `${item.to}-${index}`,
            name: item.label,
            link: item.to,
          })),
        ];
      },
      currentItem () {
        return this.menuItems.find(item => this.isActive(item));
      },
    },
    async created () {
      const contentMenuStore = useContentMenusStore();
      await contentMenuStore.initMenu('petrol-menu');
    },
    methods: {
      isActive (menuItem) {
        const route = useRoute();
        return removeLocaleFromUrl(route.path) === menuItem.link || removeLocaleFromUrl(route.path) === menuItem.link + '/' + this.petrolStation?.slug;
      },
      async clickOnPetrolMenu (menuItem) {
        const contentsStore = useContentsStore();
        contentsStore.setNeedPetrolContent(true);

        const breadcrumbStore = useBreadcrumbStore();

        await breadcrumbStore.setBreadcrumbs([
          {
            token: this.$awt('aw.common.category.home'),
            to: '/',
            disabled: false,
          },
          {
            token: this.$awt('aw.pages.petrol.title'),
            to: '/petrol',
            disabled: true,
          },
          {
            token: menuItem.label,
            to: menuItem.to,
            disabled: true,
          },
        ]);
      },
    },
  };
</script>
